import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

import api from '~/services/api';
// import { theme, styles } from '../../config/reactSelect';
import colors from '../../styles/colors';

function AsyncCombo({
  name,
  label,
  disabled,
  route,
  placeholder,
  idField,
  labelField,
  labelExtractor,
  isClearable,
  defaultValue,
  value,
  onChange,
  onChangeLabel,
  params,
  required,
}) {
  const timer = useRef(null);
  // const [value, setValue] = useState();

  /**
   * Async Select
   */
  async function loadData(filterText) {
    const response = await api.get(route, {
      params: { q: filterText, ...(params && { ...params }) },
    });

    const items = response.data.map((item) => {
      const itemValue = idField ? item[idField] : item;

      let labelValue = labelField ? item[labelField] : item;
      if (labelExtractor) {
        labelValue = labelExtractor(item);
      }

      return { value: itemValue, label: labelValue };
    });

    return items;
  }

  const loadOptions = (inputValue, callback) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(async () => {
      callback(await loadData(inputValue));
    }, 600);
  };

  const handleChange = (selectedOptions) => {
    if (onChange) {
      const newValue = selectedOptions ? selectedOptions.value : undefined;
      onChange(newValue);
    }

    if (onChangeLabel) {
      const newLabel = selectedOptions ? selectedOptions.label : undefined;
      onChangeLabel(newLabel);
    }
  };

  return (
    <>
      {label ? (
        <label htmlFor={name} style={disabled ? { color: colors.gray500 } : {}}>
          {label}
        </label>
      ) : null}

      <AsyncSelect
        // ref={refCiaAerea}
        isClearable={isClearable}
        name={name}
        placeholder={placeholder}
        cacheOptions
        loadOptions={route ? loadOptions : undefined}
        defaultOptions
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        // theme={theme}
        // styles={styles}
        isDisabled={disabled}
        required={required}
      />
    </>
  );
}

AsyncCombo.defaultProps = {
  label: '',
  disabled: false,
  placeholder: '',
  idField: undefined,
  isClearable: false,
  required: false,
  labelField: undefined,
  labelExtractor: undefined,
  defaultValue: undefined,
  value: undefined,
  params: undefined,
  onChangeLabel: undefined,
};

AsyncCombo.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  route: PropTypes.string.isRequired,
  idField: PropTypes.string,
  labelField: PropTypes.string,
  labelExtractor: PropTypes.func,
  required: PropTypes.bool,
  isClearable: PropTypes.bool,
  defaultValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onChangeLabel: PropTypes.func,
  params: PropTypes.shape(),
};

export { AsyncCombo };

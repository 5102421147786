import React from 'react';
import { toast } from 'react-toastify';
import getErrorMessage from './getErrorMessage';

const defaultStyle = {
  borderRadius: 8,
  padding: 16,
};

const defaultBodyStyle = {
  padding: '16px 24px',
};

const defaultOptions = { autoClose: 5000 };

export function showError(message, err, options) {
  toast.error(
    <div>
      <strong>{message || 'Falhou... :('}</strong>

      {err ? (
        <>
          <br />
          <br />
          {getErrorMessage(err)}
        </>
      ) : null}
    </div>,
    {
      style: { ...defaultStyle },
      bodyStyle: { ...defaultBodyStyle },
      ...defaultOptions,
      ...(options ? { ...options } : {}),
    }
  );
}

export function showSuccess(title, message, options) {
  toast.success(
    <div>
      {title ? <strong>{title}</strong> : null}

      {message ? (
        <>
          <br />
          <br />
          {message}
        </>
      ) : null}
    </div>,
    {
      style: { ...defaultStyle },
      bodyStyle: { ...defaultBodyStyle },
      ...defaultOptions,
      ...(options ? { ...options } : {}),
    }
  );
}

export function showInfo(title, message, options) {
  toast.info(
    <div>
      {title ? <strong>{title}</strong> : null}

      {message ? (
        <>
          <br />
          <br />
          {message}
        </>
      ) : null}
    </div>,

    {
      style: { ...defaultStyle },
      bodyStyle: { ...defaultBodyStyle },
      ...defaultOptions,
      ...(options ? { ...options } : {}),
    }
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { MdRefresh } from 'react-icons/md';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';

import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import SearchInput from '~/components/Inputs/SearchInput';
import ColumnHeader from '~/components/ColumnHeader';
import { FieldGroup, Field } from '~/components/Forms';
import Can from '~/components/Can';
import { LinkButton } from '~/components/Buttons/LinkButton';

import ContractStatus from '~/pages/Contracts/ContractList/ContractStatus';

import { Header } from './styles';

function ServerContracts({ server, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);
  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [serverContracts, setServerContracts] = useState([]);
  const [order, setOrder] = useState({
    field: 'customer.name',
    asc: true,
  });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadVersionContracts(f) {
      if (server) {
        setLoading(true);

        const response = await api.get(`servers/${server.id}/contracts`, {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((item) => {
          return {
            ...item,
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setServerContracts(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadVersionContracts(filter);
    }, 600);
  }, [filter, page, pageLimit, order, server, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  const handleEditContract = (contract_id) => {
    if (!contract_id) return;
    history.push({ pathname: '/contracts/edit', contract_id });
  };

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Contratos vinculados {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="contrato"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Cliente"
                showOrder={order.field === 'customer.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('customer.name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Código do Cliente"
                showOrder={order.field === 'alias'}
                asc={order.asc}
                onClick={() => handleChangeOrder('alias')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Descrição"
                showOrder={order.field === 'description'}
                asc={order.asc}
                onClick={() => handleChangeOrder('description')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'status'}
                asc={order.asc}
                onClick={() => handleChangeOrder('status')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {serverContracts.map((item) => (
            <tr key={item.id}>
              <td>{item.customer.name}</td>
              <td>
                {/* <CellWrapper> */}
                <Can
                  role={role}
                  perform="contracts:edit"
                  yes={() => (
                    <LinkButton
                      style={{ minWidth: '50px' }}
                      onClick={() => handleEditContract(item.id)}>
                      {item.alias}
                    </LinkButton>
                  )}
                  no={() => <>{item.alias}</>}
                />
                {/* </CellWrapper> */}
              </td>

              <td>{item.description}</td>
              <td>
                <ContractStatus status={item.status} />
              </td>
              <td>{item.createdAtFormatted}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && serverContracts.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

ServerContracts.propTypes = {
  server: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  maxWidthPercent: PropTypes.number,
};

ServerContracts.defaultProps = {
  server: null,
  maxWidthPercent: undefined,
};

export default ServerContracts;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';

import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';

import Pagination from '~/components/Pagination';
import SearchInput from '~/components/Inputs/SearchInput';
import ColumnHeader from '~/components/ColumnHeader';

import {
  Header,
  VerticalContainer,
  HorizontalContainer,
  Field,
  Value,
} from './styles';

function HistoryList({ id, route, maxWidthPercent }) {
  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });

  useEffect(() => {
    async function loadHistory(f) {
      if (id) {
        setLoading(true);

        const response = await api.get(`${route}/${id}/history`, {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((hist) => {
          const fields = hist.detailJson.map((det) => {
            const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

            let valueFormatted = `${det.value}`;
            if (dateRegex.test(det.value)) {
              valueFormatted = format(parseISO(det.value), 'dd/MM/yyyy');
            }

            return (
              <HorizontalContainer key={`${det.field}`}>
                {det.field === 'Download' ? (
                  <>
                    <Field>Download:</Field>
                    <Value>
                      <a
                        href={valueFormatted}
                        target="_blank"
                        rel="noopener noreferrer">
                        Clique aqui
                      </a>
                    </Value>
                  </>
                ) : (
                  <>
                    <Field>{det.field}:</Field>
                    <Value>{valueFormatted}</Value>
                  </>
                )}
              </HorizontalContainer>
            );
          });

          return {
            ...hist,
            detailFormatted: (
              <VerticalContainer key={`${hist.id}`}>{fields}</VerticalContainer>
            ),
            createdAtFormatted:
              hist.createdAt &&
              format(parseISO(hist.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setHistory(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadHistory(filter);
    }, 600);
  }, [filter, page, pageLimit, order, id, route]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleChangeOrder(field) {
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Histórico</Title>

      <Header>
        <SearchInput
          placeholder="descrição"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado por"
                showOrder={order.field === 'created_by'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_by')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Descrição"
                showOrder={order.field === 'description'}
                asc={order.asc}
                onClick={() => handleChangeOrder('description')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Detalhes"
                showOrder={order.field === 'detail'}
                asc={order.asc}
                // onClick={() => handleChangeOrder('detail')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {history.map((item) => (
            <tr key={item.id}>
              <td>{item.createdAtFormatted}</td>
              <td>{item.createdBy && item.createdBy.name}</td>
              <td>{item.description}</td>
              <td>{item.detailFormatted}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && history.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

HistoryList.propTypes = {
  id: PropTypes.number.isRequired,
  route: PropTypes.string.isRequired,
  maxWidthPercent: PropTypes.number,
};

HistoryList.defaultProps = {
  maxWidthPercent: undefined,
};

export default HistoryList;

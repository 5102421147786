import React, { useEffect, useState } from 'react';
import LoaderSpinner from 'react-loader-spinner';
import PropTypes from 'prop-types';
import api from '../../services/api';
import { Button } from '../Buttons/Button';
import colors from '../../styles/colors';
import { Container } from './styles';
import getErrorMessage from '../../lib/getErrorMessage';
import { showError, showSuccess } from '../../lib/toastHelper';

const ExecuteJobButton = ({
  label,
  route,
  payload,
  disabled,
  icon,
  isDangerous,
  setIsRunning: setIsRunningExternally,
  onStart,
  onSuccess,
  onError,
  silent,
}) => {
  const [jobId, setJobId] = useState(null);
  const [jobError, setJobError] = useState(null);
  const [jobResult, setJobResult] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (jobError && onError) {
      onError(jobError);
    }
  }, [jobError, onError]);

  useEffect(() => {
    if (jobResult && onSuccess) {
      onSuccess(jobResult);
    }
  }, [jobResult, onSuccess]);

  useEffect(() => {
    const monitorJob = async () => {
      if (!jobId) return;

      try {
        const interval = setInterval(async () => {
          const response = await api.get(`/jobs/${jobId}`);

          const { data } = response;
          const { status, result } = data;

          if (status === 'succeeded') {
            clearInterval(interval);
            setIsRunning(false);
            setJobResult(result);

            if (!silent) {
              showSuccess(label, `A operação foi realizada com sucesso!`, {
                autoClose: false,
              });
            }
          } else if (status === 'failed') {
            const error = result || `Erro durante o processo "${label}".`;
            clearInterval(interval);
            setIsRunning(false);
            setJobError(error);

            if (!silent) {
              showError(`${label}`, error, { autoClose: false });
            }
          }
        }, 5000); // Monitorar a cada 5 segundos
      } catch (err) {
        const error = err.message || `Erro ao monitorar o processo "${label}".`;
        setIsRunning(false);
        setJobError(error);
        if (!silent) {
          showError(`${label}`, error, { autoClose: false });
        }
      }
    };

    monitorJob();
  }, [jobId, label, silent]);

  const handleStartJob = async () => {
    if (!window.confirm(`Deseja mesmo iniciar "${label}"?`)) {
      return;
    }

    try {
      if (!route) {
        throw new Error('Rota do job não informada.');
      }

      if (onStart) {
        onStart();
      }

      setIsRunning(true);
      setJobError(null);
      setJobResult(null);

      const payloadParsed = payload ? { ...payload } : undefined;

      const response = await api.post(route, payloadParsed);

      const { job } = response.data;
      const { id } = job;

      setJobId(id);
    } catch (err) {
      const error = getErrorMessage(err);
      setIsRunning(false);
      setJobError(error || err.message || 'Erro desconhecido.');

      if (!silent) {
        showError(`${label}`, error, { autoClose: false });
      }
    }
  };

  useEffect(() => {
    if (setIsRunningExternally) {
      setIsRunningExternally(isRunning);
    }
  }, [isRunning, setIsRunningExternally]);

  return (
    <Container>
      <Button
        type="button"
        delete={isDangerous}
        onClick={handleStartJob}
        disabled={isRunning || disabled}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          marginLeft: 0,
        }}>
        <>
          {isRunning ? (
            <LoaderSpinner
              type="Oval"
              color={colors.iconLight}
              height={14}
              width={14}
            />
          ) : (
            icon || null
          )}
          {label}
        </>
      </Button>
    </Container>
  );
};

ExecuteJobButton.defaultProps = {
  payload: undefined,
  disabled: false,
  icon: undefined,
  isDangerous: false,
  setIsRunning: undefined,
  onStart: undefined,
  onSuccess: undefined,
  onError: undefined,
  silent: false,
};

ExecuteJobButton.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  payload: PropTypes.shape(),
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  isDangerous: PropTypes.bool,
  setIsRunning: PropTypes.func,
  onStart: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  silent: PropTypes.bool,
};

export default ExecuteJobButton;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Form, Input } from '@rocketseat/unform';
import { MdDone, MdArrowBack, MdRefresh } from 'react-icons/md';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import { showError, showInfo, showSuccess } from '~/lib/toastHelper';
import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';
import formatContract from '~/lib/formatContract';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import LicensesList from '~/pages/Licenses/LicenseList';
import SupportTokenList from '~/pages/SupportTokens/SupportTokenList';
import ContractModuleList from '~/pages/ContractModules/ContractModuleList';
import ContractVersionsList from '~/pages/ContractVersions/ContractVersionsList';
import DatabaseList from '~/pages/Databases/DatabaseList';
import ContractIntegrationList from '~/pages/ContractIntegrations/ContractIntegrationList';

import { FormWrapper, FieldGroup, Field } from '~/components/Forms';
import { HorizontalLeftContainer } from '~/components/Containers';
import { BaseContainer } from '~/components/BaseContainer';
import ClipboardButton from '~/components/ClipboardButton';
import { AsyncCombo } from '~/components/AsyncCombo';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import HistoryList from '~/components/HistoryList';
import { Title } from '~/components/Title';

import statusToStr from '../ContractList/ContractStatus/status';
import TabDB from './TabDB';
import TabCommercial from './TabCommercial';
import TabFinance from './TabFinance';
import TabTech from './TabTech';

// import { } from './styles';

function ContractForm({ location }) {
  const initializeData = useCallback((c) => {
    return {
      customer_id: c ? c.customer_id || null : null,
      customer_name: c ? (c.customer && c.customer.name) || '' : '',
      status: c ? c.status || 'active' : 'active',
      description: c ? c.description || '' : '',
      contract_token: c ? c.contract_token || '' : '',
      alias: c ? c.alias || '' : '',
      server_id: c ? c.server_id || null : null,
      server_name: c ? (c.server && c.server.name) || '' : '',
      path_db: c ? c.path_db || '' : '',
      remote_db: c ? c.remote_db || '' : '',
      username_db: c ? c.username_db || '' : '',
      password_db: c ? c.password_db || '' : '',
      max_users: c ? c.max_users : 1,
      max_licenses: c ? c.max_licenses || '' : '',
      max_companies: c ? c.max_companies || '' : '',
      start_date: c
        ? c.startDateEn || format(new Date(), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      access_block_date: c ? c.accessBlockDateEn || '' : '',
      courtesy_licenses: c ? c.courtesy_licenses || '' : '',
      commercial_note: c ? c.commercial_note || '' : '',
      block_update: c ? c.block_update : false,
      tech_note: c ? c.tech_note || '' : '',
      finance_note: c ? c.finance_note || '' : '',
      send_new_licenses_info_email: c
        ? c.send_new_licenses_info_email || false
        : false,
      admin_email: c ? c.admin_email || '' : '',
      server_info: c ? c.server_info || '' : '',
    };
  }, []);

  const contractId = useMemo(
    () => location.contract_id,
    [location.contract_id]
  );
  const [contract, setContract] = useState();
  const [data, setData] = useState(!contractId ? initializeData() : undefined);
  const [initialData, setInitialData] = useState(data);
  // const [dataKey, setDataKey] = useState(Date.now()); // Inicializa com um timestamp
  const [loading, setLoading] = useState(false);
  const [isJobRunning, setIsJobRunning] = useState(false);
  const role = useSelector((state) => state.role.role);

  const expected_updated_at = useMemo(
    () => contract && contract.updatedAt,
    [contract]
  );

  const isEditing = useMemo(
    () => history.location.pathname === '/contracts/edit',
    []
  );

  const {
    denied,
    canEdit,
    showTabCommercial,
    showTabFinance,
    showTabTech,
    showTabDB,
    showTabLicenses,
    showTabTokens,
    showTabModules,
    showTabDatabases,
    showTabVersions,
    showTabIntegrations,
  } = useMemo(() => {
    const hasPermissionToEdit = checkPermission(role, 'contracts:edit');

    return {
      denied: !isEditing && !checkPermission(role, 'contracts:create'),
      canEdit: hasPermissionToEdit,

      showTabCommercial: checkPermission(role, 'contracts/commercial:visit'),
      showTabFinance: checkPermission(role, 'contracts/finance:visit'),
      showTabTech: checkPermission(role, 'contracts/tech:visit'),
      showTabDB:
        isEditing &&
        hasPermissionToEdit &&
        checkPermission(role, 'contracts/tech:visit'),
      showTabLicenses: checkPermission(role, 'licenses:visit'),
      showTabTokens: checkPermission(role, 'support-tokens:visit'),
      showTabModules: checkPermission(role, 'contracts:visit'),
      showTabDatabases: checkPermission(role, 'contracts:visit'),
      showTabVersions: checkPermission(role, 'contracts:visit'),
      showTabIntegrations: checkPermission(role, 'contracts:visit'),
    };
  }, [role, isEditing]);

  /**
   * Effects
   */

  const fetchData = useCallback(async () => {
    if (!contractId) return;
    try {
      setLoading(true);
      const response = await api.get(`contracts/${contractId}`);
      const loadedContract = formatContract(response.data);
      setContract(loadedContract);
      const initData = initializeData(loadedContract);
      setData({ ...initData });
      setInitialData({ ...initData });

      // setDataKey(Date.now());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(error.message, error);
    }
  }, [contractId, initializeData]);

  useEffect(() => {
    fetchData();
  }, [contractId, fetchData]);

  useEffect(() => {
    if (denied) {
      history.push('/denied');
      return;
    }

    if (isEditing && !contractId) {
      history.goBack();
    }
  }, [contractId, isEditing, denied]);

  const handleChangeCustomer = (value) => {
    setData((prev) => ({ ...prev, customer_id: value }));
  };

  const handleChangeCustomerLabel = (label) => {
    setData((prev) => ({ ...prev, customer_name: label, description: label }));
  };

  const handleChangeStatus = (status) => {
    setData((prev) => ({ ...prev, status }));
  };

  const handleClickBack = () => {
    history.goBack();
  };

  async function save() {
    const payload = {
      customer_id: !contractId && data.customer_id,
      status: data.status,
      description: data.description,
      start_date: data.start_date,
      max_licenses: data.max_licenses || null,
      courtesy_licenses: data.courtesy_licenses || null,
      max_companies: data.max_companies || null,
      max_users: data.max_users || null,
      server_id: data.server_id || null,
      path_db: data.path_db || null,
      remote_db: data.remote_db || null,
      username_db: data.username_db,
      password_db: data.password_db,
      access_block_date: data.access_block_date || null,
      block_update: data.block_update,
      server_info: data.server_info || null,
      commercial_note: data.commercial_note,
      finance_note: data.finance_note,
      tech_note: data.tech_note,
      // admin_email: data.admin_email || null,
      // send_new_licenses_info_email: data.send_new_licenses_info_email,
      expected_updated_at,
    };

    try {
      if (contractId) {
        await api.put(`contracts/${contractId}`, payload); // Edit
      } else {
        await api.post('contracts', payload); // Create
      }

      showSuccess(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/contracts');
    } catch (err) {
      return showError(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = () => {
    if (!isEditing && !checkPermission(role, 'contracts:create')) {
      return;
    }
    if (isEditing && !canEdit) {
      return;
    }

    save();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnReload = async ({ requireConfirmation } = {}) => {
    const hasChanges = JSON.stringify(data) !== JSON.stringify(initialData);

    if (requireConfirmation && hasChanges) {
      const userConfirmed = window.confirm(
        'Há mudanças não salvas. Tem certeza que deseja recarregar os dados?'
      );
      if (!userConfirmed) return;
    }

    await fetchData();
    showInfo('Os dados foram recarregados');
  };

  if (!data) return null;

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <FormHeader>
              <Title>Cadastro de Contratos</Title>
              <div>
                {contractId ? (
                  <Button
                    type="button"
                    padding="6px"
                    disabled={isJobRunning || loading}
                    onClick={() =>
                      handleOnReload({ requireConfirmation: true })
                    }>
                    <MdRefresh size={24} color={colors.iconLight} />
                  </Button>
                ) : null}

                <Button
                  type="button"
                  onClick={handleClickBack}
                  disabled={isJobRunning}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={isJobRunning || (isEditing && !canEdit)}>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field flex={2}>
                <AsyncCombo
                  name="customers"
                  label="Cliente"
                  route="customers"
                  params={{ pageLimit: 100, active: true }}
                  placeholder="Selecione um cliente..."
                  idField="id"
                  labelField="name"
                  onChange={handleChangeCustomer}
                  onChangeLabel={handleChangeCustomerLabel}
                  defaultValue={
                    data && {
                      value: `${data.customer_id}`,
                      label: data.customer_name,
                    }
                  }
                  required
                  disabled={isEditing || isJobRunning}
                  isClearable
                />
              </Field>
              <Field flex={1}>
                <AsyncCombo
                  name="status"
                  label="Status"
                  route="contract-status"
                  params={{ pageLimit: 100, active: true }}
                  placeholder="Selecione um status..."
                  idField=""
                  labelField=""
                  labelExtractor={(status) => statusToStr[status]}
                  onChange={handleChangeStatus}
                  defaultValue={
                    (data && {
                      value: data.status,
                      label: statusToStr[data.status],
                    }) || { value: 'active', label: statusToStr.active }
                  }
                  required
                  isClearable
                  disabled={isJobRunning}
                />
              </Field>

              <Field>
                <label htmlFor="description">Descrição</label>
                <Input
                  name="description"
                  type="text"
                  autoComplete="off"
                  placeholder="Deixe em branco para gerar automaticamente"
                  maxLength="255"
                  value={data.description || ''}
                  onChange={handleInputChange}
                  disabled={isJobRunning}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="alias">Código do Cliente (alias)</label>
                <HorizontalLeftContainer>
                  <Input
                    name="alias"
                    type="text"
                    autoComplete="off"
                    placeholder="Gerado automaticamente"
                    maxLength="100"
                    disabled
                    value={data.alias || ''}
                  />
                  {data.alias ? (
                    <ClipboardButton value={data.alias || ''} />
                  ) : null}
                </HorizontalLeftContainer>
              </Field>
              <Field>
                <label htmlFor="contract_token">Contract Token</label>
                <HorizontalLeftContainer>
                  <Input
                    name="contract_token"
                    type="text"
                    autoComplete="off"
                    placeholder="Gerado automaticamente"
                    maxLength="32"
                    disabled
                    value={data.contract_token || ''}
                    style={{ minWidth: 300 }}
                  />
                  {data.contract_token ? (
                    <ClipboardButton value={data.contract_token || ''} />
                  ) : null}
                </HorizontalLeftContainer>
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                {(showTabFinance || showTabTech) && (
                  <Tabs>
                    <TabList>
                      {showTabCommercial && <Tab>Comercial</Tab>}
                      {showTabFinance && <Tab>Financeiro</Tab>}
                      {showTabTech && <Tab>Informações Técnicas</Tab>}
                      {showTabDB && <Tab>Banco de Dados</Tab>}
                    </TabList>

                    {showTabCommercial && (
                      <TabPanel>
                        <TabCommercial
                          contract={contract}
                          data={data}
                          setData={setData}
                          role={role}
                          isEditing={isEditing}
                          isJobRunning={isJobRunning}
                          setIsJobRunning={setIsJobRunning}
                        />
                      </TabPanel>
                    )}

                    {showTabFinance && (
                      <TabPanel>
                        <TabFinance
                          data={data}
                          setData={setData}
                          role={role}
                          isJobRunning={isJobRunning}
                          setIsJobRunning={setIsJobRunning}
                        />
                      </TabPanel>
                    )}

                    {showTabTech && (
                      <TabPanel>
                        <TabTech
                          data={data}
                          setData={setData}
                          role={role}
                          isJobRunning={isJobRunning}
                          setIsJobRunning={setIsJobRunning}
                        />
                      </TabPanel>
                    )}

                    {showTabDB && (
                      <TabPanel>
                        <TabDB
                          contract={contract}
                          data={data}
                          setData={setData}
                          role={role}
                          onReload={handleOnReload}
                          isJobRunning={isJobRunning}
                          setIsJobRunning={setIsJobRunning}
                        />
                      </TabPanel>
                    )}
                  </Tabs>
                )}
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        {/* Abas inferiores */}
        <Field>
          <Tabs>
            <TabList>
              {showTabLicenses && <Tab>Licenças</Tab>}
              {showTabModules && <Tab>Módulos</Tab>}
              {showTabTokens && <Tab>Tokens de Atendimento</Tab>}
              {showTabDatabases && <Tab>Databases</Tab>}
              {showTabVersions && <Tab>Versões</Tab>}
              {showTabIntegrations && <Tab>Integrações</Tab>}
              {canEdit && <Tab>Histórico</Tab>}
            </TabList>

            {showTabLicenses && (
              <TabPanel>
                {contract && (
                  <LicensesList contract={contract} maxWidthPercent={100} />
                )}
              </TabPanel>
            )}

            {showTabModules && (
              <TabPanel>
                {contract && (
                  <ContractModuleList
                    contract={contract}
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}

            {showTabTokens && (
              <TabPanel>
                {contract && (
                  <SupportTokenList contract={contract} maxWidthPercent={100} />
                )}
              </TabPanel>
            )}

            {showTabDatabases && (
              <TabPanel>
                {contract && (
                  <DatabaseList contract={contract} maxWidthPercent={100} />
                )}
              </TabPanel>
            )}

            {showTabVersions && (
              <TabPanel>
                {contract && (
                  <ContractVersionsList
                    contract={contract}
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}

            {showTabIntegrations && (
              <TabPanel>
                {contract && (
                  <ContractIntegrationList
                    contract={contract}
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}

            {canEdit && (
              <TabPanel>
                {contract && (
                  <HistoryList
                    id={contract.id}
                    route="contracts"
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

ContractForm.propTypes = {
  location: PropTypes.shape(),
};

ContractForm.defaultProps = {
  location: null,
};

export default ContractForm;

import React, { useState, useEffect, useRef } from 'react';
// import InputMask from 'react-input-mask';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { Form, Input, Check } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';
import VersionContractList from '~/pages/VersionContracts/VersionContractList';
import VersionReleaseGroupList from '~/pages/VersionReleaseGroups/VersionReleaseGroupList';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function VersionForm({ location }) {
  const [version] = useState(location.version);
  const isEditing = history.location.pathname === '/versions/edit';
  const timerApplication = useRef(null);
  const [selectedApplication, setSelectedApplication] = useState(
    version && version.application
  );

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'versions:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !version) {
      history.goBack();
    }
  }, [version, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ application_id, versionStr, url, obs, active }) {
    try {
      if (version) {
        await api.put(`versions/${version.id}`, {
          version: versionStr,
          url,
          obs,
          active,
        }); // Edit
      } else {
        await api.post('versions', {
          application_id,
          version: versionStr,
          url,
          obs,
        }); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/versions');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ version: versionStr, url, obs, active }) => {
    if (!isEditing && !checkPermission(role, 'versions:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'versions:edit')) {
      return;
    }

    save({
      application_id: selectedApplication && selectedApplication.id,
      versionStr,
      url,
      obs,
      active,
    });
  };

  /**
   * Async Select (Applications)
   */
  async function loadApplications(filter) {
    const response = await api.get('applications', {
      params: { q: filter, active: true },
    });

    const applications = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });

    return applications;
  }

  const loadApplicationOptions = (inputValue, callback) => {
    clearTimeout(timerApplication.current);
    timerApplication.current = setTimeout(async () => {
      callback(await loadApplications(inputValue));
    }, 600);
  };

  const handleChangeApplication = (selectedOptions) => {
    setSelectedApplication({
      id: selectedOptions.value,
      name: selectedOptions.label,
    });
  };

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={version}>
            <fieldset>
              <FormHeader>
                <Title>Cadastro de Versão</Title>
                <div>
                  <Button type="button" onClick={handleClickBack}>
                    <MdArrowBack size={24} color={colors.iconLight} />
                    <span>Voltar</span>
                  </Button>
                  <Button
                    primary
                    type="submit"
                    disabled={
                      isEditing && !checkPermission(role, 'versions:edit')
                    }>
                    <MdDone size={24} color={colors.iconLight} />
                    <span>Salvar</span>
                  </Button>
                </div>
              </FormHeader>

              <FieldGroup>
                <Field flex={1}>
                  <label htmlFor="applications">Aplicação</label>
                  <AsyncSelect
                    autoFocus
                    name="applications"
                    placeholder="Selecione uma aplicação..."
                    cacheOptions
                    loadOptions={loadApplicationOptions}
                    defaultOptions
                    onChange={handleChangeApplication}
                    isDisabled={isEditing}
                    defaultValue={
                      version && {
                        value: version.application.id,
                        label: version.application.name,
                      }
                    }
                    required
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field flex="none">
                  <label htmlFor="version">Versão</label>
                  <Input
                    name="version"
                    type="text"
                    maxLength="255"
                    placeholder="ex: 1.0.0.0"
                    autoComplete="off"
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field flex="1">
                  <label htmlFor="url">URL</label>
                  <Input
                    name="url"
                    type="text"
                    maxLength="255"
                    autoComplete="off"
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field flex="1">
                  <label htmlFor="obs">Observação</label>
                  <Input
                    name="obs"
                    type="text"
                    maxLength="255"
                    autoComplete="off"
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field flex="none">
                  <label htmlFor="active">Ativo</label>
                  {isEditing ? (
                    <Check name="active" />
                  ) : (
                    <Check name="active" disabled />
                  )}
                </Field>
              </FieldGroup>
            </fieldset>
          </Form>
        </FormWrapper>

        <Field>
          <Tabs>
            <TabList>
              <Tab>Grupos de Atualização</Tab>
              <Tab>Contratos</Tab>
            </TabList>

            {version && (
              <TabPanel>
                <VersionReleaseGroupList
                  version={version}
                  maxWidthPercent={100}
                />
              </TabPanel>
            )}

            {version && (
              <TabPanel>
                <VersionContractList version={version} maxWidthPercent={100} />
              </TabPanel>
            )}
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

VersionForm.propTypes = {
  location: PropTypes.shape(),
};

VersionForm.defaultProps = {
  location: null,
};

export default VersionForm;

import React from 'react';
import { Check, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Field, FieldGroup } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';

function TabTech({ data, setData, role, isJobRunning }) {
  const editTabTech = checkPermission(role, 'contracts/tech:edit');

  /**
   * Handlers
   */
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <FieldGroup>
        <Field flex="none">
          <label htmlFor="block_update">Bloquear Atualizações</label>
          <Check
            name="block_update"
            disabled={!editTabTech || isJobRunning}
            checked={data.block_update}
            onChange={() =>
              setData((prev) => ({
                ...prev,
                block_update: !data.block_update,
              }))
            }
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field>
          <label htmlFor="server_info">Servidor</label>
          <Input
            name="server_info"
            type="text"
            maxLength="255"
            autoComplete="off"
            disabled={!editTabTech || isJobRunning}
            value={data.server_info || ''}
            onChange={handleInputChange}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field>
          <Input
            multiline
            name="tech_note"
            label="Observações Técnicas"
            maxLength={500}
            style={{
              resize: 'none',
              border: '1px solid #ccc',
              borderRadius: '3px',
              height: '70px',
            }}
            value={data.tech_note || ''}
            onChange={handleInputChange}
            disabled={!editTabTech || isJobRunning}
          />
        </Field>
      </FieldGroup>
    </>
  );
}

TabTech.propTypes = {
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isJobRunning: PropTypes.bool,
};

TabTech.defaultProps = {
  isJobRunning: false,
};

export default TabTech;

import React, { useEffect, useMemo, useState } from 'react';
import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import {
  MdAdd,
  MdBackup,
  MdDeleteForever,
  MdEdit,
  MdVisibility,
  MdVisibilityOff,
  MdImportExport,
} from 'react-icons/md';

import { HorizontalSpacedContainer } from '~/components/Containers';
import ExecuteJobButton from '~/components/ExecuteJobButton';
import { Field, FieldGroup } from '~/components/Forms';
import { AsyncCombo } from '~/components/AsyncCombo';
import { Button } from '~/components/Buttons/Button';
import NewDBModal from '~/components/business/NewDBModal';

import api from '~/services/api';

import { extractFirebirdConnectionParts } from '~/lib/extractFirebirdConnectionParts';
import checkPermission from '~/lib/checkPermission';

import colors from '~/styles/colors';

import MoveDBModal from './MoveDBModal';

// import { Container } from './styles';

function TabDB({
  contract,
  data,
  setData,
  role,
  onReload,
  isJobRunning,
  setIsJobRunning,
}) {
  const [server, setServer] = useState();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [isNewDBModalVisible, setIsNewDBModalVisible] = useState(false);
  const [isMoveDBModalVisible, setIsMoveDBModalVisible] = useState(false);

  const canEdit = useMemo(
    () => checkPermission(role, 'contracts:edit'),
    [role]
  );

  const { showCreateDb, showBackupDb, showDeleteDb, showMoveDb } =
    useMemo(() => {
      return {
        showCreateDb:
          canEdit &&
          isFieldsDisabled &&
          contract &&
          contract.status === 'active' &&
          !contract.server_id &&
          !contract.path_db &&
          !contract.remote_db &&
          !contract.username_db &&
          !contract.password_db &&
          !data.server_id &&
          !data.path_db &&
          !data.remote_db &&
          !data.username_db &&
          !data.password_db,

        showBackupDb:
          canEdit &&
          contract &&
          !!contract.server_id &&
          !!contract.path_db &&
          !!data.server_id &&
          !!data.path_db &&
          contract.path_db === data.path_db &&
          contract.server_id === data.server_id,

        showDeleteDb:
          canEdit &&
          contract &&
          contract.status === 'cancel' &&
          !!contract.path_db,

        showMoveDb:
          canEdit &&
          contract &&
          contract.status === 'active' &&
          !!contract.server_id &&
          !!contract.path_db,
      };
    }, [
      contract,
      canEdit,
      isFieldsDisabled,
      data.password_db,
      data.path_db,
      data.remote_db,
      data.server_id,
      data.username_db,
    ]);

  /**
   * effects
   */
  useEffect(() => {
    async function loadServer() {
      if (!data.server_id) return;
      const response = await api.get(`servers/${data.server_id}`);
      setServer(response.data);
    }
    loadServer();
  }, [data.server_id]);

  useEffect(() => {
    if (!server || isFieldsDisabled) return;

    let newPathDb = server.host;
    if (data.path_db) {
      const { host } = extractFirebirdConnectionParts(data.path_db);
      if (host) {
        newPathDb = data.path_db.replace(host, server.host);
      }
    }

    let newRemoteDb = server.host;
    if (data.remote_db) {
      const { host } = extractFirebirdConnectionParts(data.remote_db);
      if (host) {
        newRemoteDb = data.remote_db.replace(host, server.host);
      }
    }

    setData((prev) => ({
      ...prev,
      path_db: newPathDb,
      remote_db: newRemoteDb,
      username_db: server.username_db,
      password_db: server.password_db,
    }));
  }, [setData, server]);

  /**
   * Handlers
   */
  const handleServerChange = (id) => {
    setData((prev) => ({ ...prev, server_id: id }));
  };
  const handleServerLabelChange = (name) => {
    setData((prev) => ({ ...prev, server_name: name }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // New DB
  const handleCloseNewDBModal = () => {
    setIsNewDBModalVisible(false);
  };

  const handleNewDBSuccees = () => {
    setIsNewDBModalVisible(false);

    if (onReload) {
      onReload();
    }
  };

  // Move DB
  const handleCloseMoveDBModal = () => {
    setIsMoveDBModalVisible(false);
  };

  const handleMoveDBSuccees = () => {
    setIsMoveDBModalVisible(false);

    if (onReload) {
      onReload();
    }
  };

  // Delete DB
  const handleDeleteDBSuccees = () => {
    if (onReload) {
      onReload();
    }
  };

  const handleEdit = () => {
    if (
      isFieldsDisabled &&
      !window.confirm(
        `Deseja mesmo alterar os dados manualmente? (não recomendado)`
      )
    ) {
      return;
    }
    setIsFieldsDisabled(!isFieldsDisabled);
  };

  return (
    <>
      {contract && isNewDBModalVisible ? (
        <NewDBModal
          contract_id={contract.id}
          customer={contract.customer && contract.customer.name}
          isVisible={isNewDBModalVisible}
          onClose={handleCloseNewDBModal}
          onSuccess={handleNewDBSuccees}
          isJobRunning={isJobRunning}
          setIsJobRunning={setIsJobRunning}
        />
      ) : null}

      {contract && isMoveDBModalVisible ? (
        <MoveDBModal
          contract_id={contract.id}
          isVisible={isMoveDBModalVisible}
          onClose={handleCloseMoveDBModal}
          onSuccess={handleMoveDBSuccees}
          isJobRunning={isJobRunning}
          setIsJobRunning={setIsJobRunning}
        />
      ) : null}

      <FieldGroup>
        <Field>
          <HorizontalSpacedContainer>
            <Field flex="none">
              {/* <label htmlFor="none">&nbsp;</label> */}
              <Button
                style={{ marginLeft: 0 }}
                type="button"
                onClick={handleEdit}
                disabled={isJobRunning}>
                <MdEdit size={18} color={colors.iconLight} />
                Alterar manualmente
              </Button>
            </Field>

            {showCreateDb && (
              <Field flex="none">
                <Button
                  type="button"
                  primary
                  style={{ margin: 0 }}
                  onClick={() => setIsNewDBModalVisible(true)}
                  disabled={isJobRunning}>
                  <MdAdd size={18} color={colors.iconLight} />
                  Novo Banco de Dados
                </Button>
              </Field>
            )}

            {showBackupDb || showDeleteDb || showMoveDb ? (
              <Field flex="none">
                <FieldGroup>
                  {showBackupDb && (
                    <Field flex="none">
                      <ExecuteJobButton
                        label="Backup"
                        route={`/contracts/${contract.id}/backup-firebird-db/`}
                        icon={<MdBackup size={18} color={colors.iconLight} />}
                        setIsRunning={setIsJobRunning}
                        disabled={isJobRunning}
                      />
                    </Field>
                  )}

                  {showDeleteDb && (
                    <Field flex="none">
                      <ExecuteJobButton
                        label="Encerrar banco de dados"
                        route={`/contracts/${contract.id}/delete-firebird-db/`}
                        icon={
                          <MdDeleteForever size={18} color={colors.statusRed} />
                        }
                        isDangerous
                        setIsRunning={setIsJobRunning}
                        onSuccess={handleDeleteDBSuccees}
                        disabled={isJobRunning}
                      />
                    </Field>
                  )}

                  {showMoveDb && (
                    <Field flex="none">
                      <Button
                        type="button"
                        delete
                        style={{ margin: 0 }}
                        onClick={() => setIsMoveDBModalVisible(true)}
                        disabled={isJobRunning}>
                        <MdImportExport size={18} color={colors.statusRed} />
                        Mover para outro servidor
                      </Button>
                    </Field>
                  )}
                </FieldGroup>
              </Field>
            ) : null}
          </HorizontalSpacedContainer>
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field>
          <AsyncCombo
            name="server"
            label="Servidor"
            route="servers"
            placeholder="Selecione um servidor..."
            isClearable
            disabled={isFieldsDisabled || isJobRunning}
            idField="id"
            // labelField="name"
            value={
              data && {
                value: `${data.server_id}`,
                label: data.server_name,
              }
            }
            labelExtractor={(item) =>
              `${item.name} (${item.contract_count}${
                item.max_databases ? `/${item.max_databases}` : ''
              })`
            }
            onChange={handleServerChange}
            onChangeLabel={handleServerLabelChange}
            params={{ pageLimit: 100, active: true, availableOnly: true }}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field>
          <label htmlFor="path_db">Path DB</label>
          <Input
            name="path_db"
            type="text"
            maxLength="500"
            autoComplete="off"
            value={data.path_db || ''}
            onChange={handleInputChange}
            disabled={isFieldsDisabled || isJobRunning}
          />
        </Field>
        <Field>
          <label htmlFor="remote_db">Remote DB</label>
          <Input
            name="remote_db"
            type="text"
            maxLength="500"
            autoComplete="off"
            disabled={isFieldsDisabled || isJobRunning}
            value={data.remote_db || ''}
            onChange={handleInputChange}
          />
        </Field>
      </FieldGroup>

      {canEdit && (
        <FieldGroup>
          <Field flex="none">
            <label htmlFor="username_db">Username DB</label>
            <Input
              name="username_db"
              type={showPass ? 'text' : 'password'}
              maxLength="100"
              autoComplete="off"
              value={data.username_db || ''}
              onChange={handleInputChange}
              disabled={isFieldsDisabled || isJobRunning}
            />
          </Field>
          <Field flex="none">
            <label htmlFor="password_db">Password DB</label>
            <Input
              style={{ flex: 1 }}
              name="password_db"
              type={showPass ? 'text' : 'password'}
              maxLength="100"
              autoComplete="off"
              value={data.password_db || ''}
              onChange={handleInputChange}
              disabled={isFieldsDisabled || isJobRunning}
            />
          </Field>
          <Field flex="none">
            <label htmlFor="none">&nbsp;</label>
            <Button
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                marginLeft: 0,
                maxWidth: 200,
              }}
              type="button"
              onClick={() => setShowPass(!showPass)}>
              {showPass ? (
                <>
                  <MdVisibility size={18} color={colors.iconLight} />
                </>
              ) : (
                <>
                  <MdVisibilityOff size={18} color={colors.iconLight} />
                </>
              )}
            </Button>
          </Field>
        </FieldGroup>
      )}
    </>
  );
}

TabDB.propTypes = {
  contract: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  onReload: PropTypes.func.isRequired,
  isJobRunning: PropTypes.bool,
  setIsJobRunning: PropTypes.func,
};

TabDB.defaultProps = {
  isJobRunning: false,
  setIsJobRunning: undefined,
};

export default TabDB;

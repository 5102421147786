import React from 'react';
import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Field, FieldGroup } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';

function TabFinance({ data, setData, role, isJobRunning }) {
  const editTabFinance = checkPermission(role, 'contracts/finance:edit');

  /**
   * Handlers
   */
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <FieldGroup>
        <Field flex="none">
          <label htmlFor="access_block_date">Data de Bloqueio</label>
          <Input
            name="access_block_date"
            type={data.access_block_date ? 'date' : 'text'}
            autoComplete="off"
            value={data.access_block_date || ''}
            onChange={handleInputChange}
            onFocus={(e) => {
              e.target.type = 'date';
            }}
            onBlur={(e) => {
              e.target.type = data.access_block_date ? 'date' : 'text';
            }}
            disabled={!editTabFinance || isJobRunning}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field>
          <Input
            multiline
            name="finance_note"
            label="Observações Financeiras"
            maxLength={500}
            style={{
              resize: 'none',
              border: '1px solid #ccc',
              borderRadius: '3px',
              height: '70px',
            }}
            value={data.finance_note || ''}
            onChange={handleInputChange}
            disabled={!editTabFinance || isJobRunning}
          />
        </Field>
      </FieldGroup>
    </>
  );
}

TabFinance.propTypes = {
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isJobRunning: PropTypes.bool,
};

TabFinance.defaultProps = {
  isJobRunning: false,
};

export default TabFinance;

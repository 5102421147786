import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import ResetPassword from '../pages/ResetPassword';
import Dashboard from '../pages/Dashboard';
import ContractList from '../pages/Contracts/ContractList';
import ContractForm from '../pages/Contracts/ContractForm';
import UserList from '../pages/Users/UserList';
import UserForm from '../pages/Users/UserForm';
import ApplicationList from '../pages/Applications/ApplicationList';
import ApplicationForm from '../pages/Applications/ApplicationForm';
import CustomerList from '../pages/Customers/CustomerList';
import CustomerForm from '../pages/Customers/CustomerForm';
import ProfileForm from '../pages/Profile/ProfileForm';
import Denied from '../pages/Denied';
import ExceptionList from '../pages/Exceptions/ExceptionList';
import ExceptionDashboard from '../pages/Exceptions/ExceptionDashboard';
import ExternalConfigsList from '../pages/ExternalConfigs/ExternalConfigsList';
import ExternalConfigsForm from '../pages/ExternalConfigs/ExternalConfigsForm';
import ApplicationMessagesList from '../pages/ApplicationMessages/ApplicationMessagesList';
import NpsSurveyList from '../pages/NpsSurveys/NpsSurveyList';
import VersionList from '../pages/Versions/VersionList';
import VersionForm from '../pages/Versions/VersionForm';
import CommunicationsList from '../pages/Communications/CommunicationsList';
import CommunicationsForm from '../pages/Communications/CommunicationsForm';
import ModuleList from '../pages/Modules/ModuleList';
import ModuleForm from '../pages/Modules/ModuleForm';
import PartnerList from '../pages/Partners/PartnerList';
import PartnerForm from '../pages/Partners/PartnerForm';
import ReleaseGroupList from '../pages/ReleaseGroups/ReleaseGroupList';
import ReleaseGroupForm from '../pages/ReleaseGroups/ReleaseGroupForm';
import ContractReport from '../pages/Reports/ContractReport';
import ServerList from '../pages/Servers/ServerList';
import ServerForm from '../pages/Servers/ServerForm';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/reset-password" exact component={ResetPassword} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/denied" exact component={Denied} isPrivate />

      {/* Contracts */}
      <Route path="/contracts" exact component={ContractList} isPrivate />
      <Route
        path="/contracts/create"
        exact
        component={ContractForm}
        isPrivate
      />
      <Route path="/contracts/edit" exact component={ContractForm} isPrivate />

      {/* Users */}
      <Route path="/users" exact component={UserList} isPrivate />
      <Route path="/users/create" exact component={UserForm} isPrivate />
      <Route path="/users/edit" exact component={UserForm} isPrivate />

      <Route path="/profile/edit" exact component={ProfileForm} isPrivate />

      {/* Customers */}
      <Route path="/customers" exact component={CustomerList} isPrivate />
      <Route
        path="/customers/create"
        exact
        component={CustomerForm}
        isPrivate
      />
      <Route path="/customers/edit" exact component={CustomerForm} isPrivate />

      {/* Applications */}
      <Route path="/applications" exact component={ApplicationList} isPrivate />
      <Route
        path="/applications/create"
        exact
        component={ApplicationForm}
        isPrivate
      />
      <Route
        path="/applications/edit"
        exact
        component={ApplicationForm}
        isPrivate
      />

      {/* Settings */}
      <Route
        path="/external-configs"
        exact
        component={ExternalConfigsList}
        isPrivate
      />
      <Route
        path="/external-configs/create"
        exact
        component={ExternalConfigsForm}
        isPrivate
      />
      <Route
        path="/external-configs/edit"
        exact
        component={ExternalConfigsForm}
        isPrivate
      />

      {/* Exceptions */}
      <Route path="/exceptions" exact component={ExceptionList} isPrivate />
      <Route
        path="/exceptions-dashboard"
        exact
        component={ExceptionDashboard}
        isPrivate
      />

      {/* ApplicationMessages */}
      <Route
        path="/application-messages"
        exact
        component={ApplicationMessagesList}
        isPrivate
      />

      {/* NpsSurveys */}
      <Route path="/nps-surveys" exact component={NpsSurveyList} isPrivate />

      {/* Versions */}
      <Route path="/versions" exact component={VersionList} isPrivate />
      <Route path="/versions/create" exact component={VersionForm} isPrivate />
      <Route path="/versions/edit" exact component={VersionForm} isPrivate />

      {/* Communications */}
      <Route
        path="/communications"
        exact
        component={CommunicationsList}
        isPrivate
      />
      <Route
        path="/communications/create"
        exact
        component={CommunicationsForm}
        isPrivate
      />
      <Route
        path="/communications/edit"
        exact
        component={CommunicationsForm}
        isPrivate
      />

      {/* Modules */}
      <Route path="/modules" exact component={ModuleList} isPrivate />
      <Route path="/modules/create" exact component={ModuleForm} isPrivate />
      <Route path="/modules/edit" exact component={ModuleForm} isPrivate />

      {/* Partners */}
      <Route path="/partners" exact component={PartnerList} isPrivate />
      <Route path="/partners/create" exact component={PartnerForm} isPrivate />
      <Route path="/partners/edit" exact component={PartnerForm} isPrivate />

      {/* Release Groups */}
      <Route
        path="/release-groups"
        exact
        component={ReleaseGroupList}
        isPrivate
      />
      <Route
        path="/release-groups/create"
        exact
        component={ReleaseGroupForm}
        isPrivate
      />
      <Route
        path="/release-groups/edit"
        exact
        component={ReleaseGroupForm}
        isPrivate
      />

      {/* Reports */}
      <Route
        path="/reports/contracts"
        exact
        component={ContractReport}
        isPrivate
      />

      {/* Servers */}
      <Route path="/servers" exact component={ServerList} isPrivate />
      <Route path="/servers/create" exact component={ServerForm} isPrivate />
      <Route path="/servers/edit" exact component={ServerForm} isPrivate />
    </Switch>
  );
}

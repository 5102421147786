import React from 'react';
import { Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { Field, FieldGroup, FieldInlineWrapper } from '~/components/Forms';
import ContractCompanyCount from '~/components/business/ContractCompanyCount';
import ContractUserCount from '~/components/business/ContractUserCount';

import checkPermission from '~/lib/checkPermission';

function TabCommercial({
  contract,
  data,
  setData,
  role,
  isEditing,
  isJobRunning,
}) {
  const editTabCommercial = checkPermission(role, 'contracts/commercial:edit');

  /**
   * Handlers
   */
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <FieldGroup>
        <Field flex="none">
          <label htmlFor="start_date">Data de Contratação</label>
          <Input
            name="start_date"
            type="date"
            autoComplete="off"
            value={data.start_date || ''}
            onChange={handleInputChange}
            required
            disabled={!editTabCommercial || isJobRunning}
          />
        </Field>

        <Field flex="none">
          <label htmlFor="max_licenses">Limite de Licenças</label>
          <Input
            name="max_licenses"
            type="number"
            autoComplete="off"
            value={data.max_licenses || ''}
            onChange={handleInputChange}
            disabled={!editTabCommercial || isJobRunning}
            maxLength={2}
            max={99}
            min={0}
          />
        </Field>

        <Field flex="none">
          <label htmlFor="courtesy_licenses">Licenças Cortesia</label>
          <Input
            name="courtesy_licenses"
            type="number"
            autoComplete="off"
            value={data.courtesy_licenses || ''}
            onChange={handleInputChange}
            disabled={!editTabCommercial || isJobRunning}
            maxLength={2}
            min={0}
            max={99}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <label htmlFor="max_users">Limite de Usuários</label>
          <FieldInlineWrapper>
            <Input
              name="max_users"
              type="number"
              autoComplete="off"
              value={data.max_users || ''}
              onChange={handleInputChange}
              disabled={!editTabCommercial || isJobRunning}
              min={0}
              max={99}
            />

            {isEditing ? (
              <ContractUserCount contract_id={contract && contract.id} />
            ) : null}
          </FieldInlineWrapper>
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <label htmlFor="max_companies">Limite de Empresas</label>
          <FieldInlineWrapper>
            <Input
              name="max_companies"
              type="number"
              autoComplete="off"
              value={data.max_companies || ''}
              onChange={handleInputChange}
              disabled={!editTabCommercial || isJobRunning}
              min={0}
              max={99}
            />

            {isEditing ? (
              <ContractCompanyCount contract_id={contract && contract.id} />
            ) : null}
          </FieldInlineWrapper>
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field>
          <Input
            multiline
            name="commercial_note"
            label="Observações Comerciais"
            maxLength={500}
            style={{
              resize: 'none',
              border: '1px solid #ccc',
              borderRadius: '3px',
              height: '70px',
            }}
            value={data.commercial_note || ''}
            onChange={handleInputChange}
            disabled={!editTabCommercial || isJobRunning}
          />
        </Field>
      </FieldGroup>
    </>
  );
}

TabCommercial.propTypes = {
  contract: PropTypes.shape(),
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  isJobRunning: PropTypes.bool,
};

TabCommercial.defaultProps = {
  contract: undefined,
  isEditing: false,
  isJobRunning: false,
};

export default TabCommercial;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { MdClose } from 'react-icons/md';
import { Container, Body, Header } from './styles';
import { Button } from '../Buttons/Button';
import colors from '../../styles/colors';

const Modal = ({ isShowing, hide, hideDisabled, header, children }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <Container>
            <div className="modal-overlay" />
            <div
              className="modal-wrapper"
              aria-modal
              aria-hidden
              tabIndex={-1}
              role="dialog">
              <div className="modal">
                <Body>
                  {/* <div className="modal-header"> */}
                  <Header>
                    {header || null}
                    <Button
                      type="button"
                      style={{ padding: 8 }}
                      onClick={hide}
                      disabled={hideDisabled}>
                      <MdClose size={18} color={colors.iconLight} />
                    </Button>
                  </Header>
                  {/* </div> */}

                  {children}
                </Body>
              </div>
            </div>
          </Container>
        </>,
        document.body
      )
    : null;

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  hideDisabled: PropTypes.bool,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  hide: undefined,
  hideDisabled: false,
  header: null,
};

export default Modal;

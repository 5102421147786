import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@rocketseat/unform';
import { MdArrowBack, MdDone } from 'react-icons/md';
import PropTypes from 'prop-types';

import colors from '~/styles/colors';

import { Field, FieldGroup, FormWrapper } from '~/components/Forms';
import { InvisibleButton } from '~/components/Buttons/InvisibleButton';
import ExecuteJobButton from '~/components/ExecuteJobButton';
import { AsyncCombo } from '~/components/AsyncCombo';
import { FormHeader } from '~/components/FormHeader';
import { Title } from '~/components/Title';
import Modal from '~/components/Modal';

function MoveDBModal({
  contract_id,
  isVisible,
  onClose,
  onSuccess,
  isJobRunning,
  setIsJobRunning,
}) {
  /**
   * States
   */
  const [loading] = useState(false);
  const [serverId, setServerId] = useState();

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);

  // const [result, setResult] = useState();
  // const [error, setError] = useState();

  // Formatando os segundos para mm:ss
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, '0');
    const secs = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  };

  // Iniciar o timer
  const startTimer = () => {
    if (!isRunning) {
      setSeconds(0);
      setIsRunning(true);
      timerRef.current = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }
  };

  // Parar o timer
  const stopTimer = () => {
    setIsRunning(false);
    clearInterval(timerRef.current);
  };

  // Limpar o timer ao desmontar
  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  /**
   * Handlers
   */
  const handleCancel = () => {
    if (!loading && !isJobRunning && !!onClose) {
      onClose();
    }
  };

  const handleServerChange = (newValue) => {
    setServerId(newValue);
  };

  const handleSuccess = (value) => {
    stopTimer();
    if (onSuccess) {
      onSuccess(value);
    }
  };

  const handleError = () => {
    stopTimer();
  };

  const handleStart = () => {
    startTimer();
  };

  return (
    <Modal
      isShowing={isVisible}
      hide={handleCancel}
      hideDisabled={loading || isJobRunning}
      header={
        <FormHeader
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Title>Mover para outro servidor</Title>
          <p>
            Atenção! o banco de dados será fisicamente movido para outro
            servidor.
          </p>
        </FormHeader>
      }>
      <strong>Ações que serão executadas:</strong>
      <ul>
        <li>- Será feito backup do banco de dados no servidor atual.</li>
        <li>- O banco de dados será encerrado no servidor atual.</li>
        <li>
          - Será criado um novo banco de dados a partir do backup no servidor
          novo.
        </li>
      </ul>
      <br />
      <p>
        Durante o procedimento, o banco ficará inacessível e suas credenciais{' '}
        <br />
        de acesso poderão mudar.
      </p>
      <br />

      <div>{`Tempo: ${formatTime(seconds)}`}</div>
      <br />

      <FormWrapper style={{ minWidth: 200 }}>
        <Form>
          <FieldGroup>
            <Field flex={1}>
              <AsyncCombo
                name="server"
                label="Servidor de destino"
                route="servers"
                placeholder="Selecione..."
                isClearable
                idField="id"
                // labelField="name"
                labelExtractor={(item) =>
                  `${item.name} (${item.contract_count}${
                    item.max_databases ? `/${item.max_databases}` : ''
                  })`
                }
                onChange={handleServerChange}
                params={{ pageLimit: 100, active: true, availableOnly: true }}
                disabled={isJobRunning}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={1}>
              <FormHeader>
                <div />
                <div>
                  <InvisibleButton
                    type="button"
                    onClick={handleCancel}
                    disabled={loading || isJobRunning}>
                    <MdArrowBack size={24} color={colors.iconLight} />
                    <span>Cancelar</span>
                  </InvisibleButton>
                  <ExecuteJobButton
                    label="Mover banco de dados"
                    route={`/contracts/${contract_id}/move-firebird-db/`}
                    payload={{ new_server_id: serverId }}
                    icon={<MdDone size={18} color={colors.iconLight} />}
                    disabled={!serverId}
                    setIsRunning={setIsJobRunning}
                    onStart={handleStart}
                    onSuccess={handleSuccess}
                    onError={handleError}
                  />
                </div>
              </FormHeader>
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </Modal>
  );
}

MoveDBModal.propTypes = {
  contract_id: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  isJobRunning: PropTypes.bool,
  setIsJobRunning: PropTypes.func,
};

MoveDBModal.defaultProps = {
  onClose: undefined,
  onSuccess: undefined,
  isJobRunning: false,
  setIsJobRunning: undefined,
};

export default MoveDBModal;

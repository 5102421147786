import React, { useState, useEffect } from 'react';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Check, Form, Input } from '@rocketseat/unform';
import {
  MdDone,
  MdArrowBack,
  MdVisibility,
  MdVisibilityOff,
  MdWarning,
} from 'react-icons/md';
import { toast } from 'react-toastify';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

// import HistoryList from '~/components/HistoryList';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title, TitleH3 } from '~/components/Title';
import {
  FormWrapper,
  FieldGroup,
  Field,
  FieldInlineWrapper,
} from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import ServerContracts from '../ServerContracts';

// import { } from './styles';

function ServerForm({ location }) {
  const [server] = useState(location.server);
  const isEditing = history.location.pathname === '/servers/edit';
  const [maxDatabases, setMaxDatabases] = useState(
    server && server.max_databases
  );
  // const [usernameDb, setUsernameDb] = useState(server && server.username_db);
  const [passwordDb, setPasswordDb] = useState(server && server.password_db);
  const [obs, setObs] = useState(server && server.obs);
  const [showPass, setShowPass] = useState(false);
  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'servers:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !server) {
      history.goBack();
    }
  }, [server, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save(name, host, username_db, prefix_db, active) {
    const payload = {
      name,
      host,
      username_db: username_db || null,
      password_db: passwordDb || null,
      prefix_db: prefix_db || null,
      max_databases: maxDatabases || null,
      obs: obs || null,
      active,
    };

    try {
      if (server) {
        await api.put(`servers/${server.id}`, payload); // Edit
      } else {
        await api.post('servers', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/servers');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ name, host, username_db, prefix_db, active }) => {
    if (!isEditing && !checkPermission(role, 'servers:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'servers:edit')) {
      return;
    }

    save(name, host, username_db, prefix_db, active);
  };

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form
            onSubmit={handleSubmit}
            initialData={{ ...server, active: server ? server.active : true }}>
            <FormHeader>
              <Title>Cadastro de Servidores</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'servers:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field>
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="255"
                  autoFocus
                  autoComplete="off"
                  required
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="max_databases">Limite de Bancos de Dados</label>
                <FieldInlineWrapper>
                  <Input
                    name="max_databases"
                    type="number"
                    autoComplete="off"
                    value={maxDatabases || ''}
                    onChange={(e) => setMaxDatabases(e.target.value)}
                    min={1}
                    max={99}
                    required
                  />
                </FieldInlineWrapper>
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="active">Ativo</label>
                <Check name="active" />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <Input
                  multiline
                  name="obs"
                  label="Observações"
                  maxLength={255}
                  style={{
                    resize: 'none',
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                    height: '70px',
                  }}
                  value={obs}
                  onChange={(e) => setObs(e.target.value)}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <label htmlFor="prefix_db">Prefixo</label>
                <Input
                  name="prefix_db"
                  type="text"
                  maxLength="255"
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex="none">
                <MdWarning size={24} color={colors.statusYellow} />
              </Field>
              <Field flex="none">
                <TitleH3>
                  Atenção: Os campos abaixo atualizam os contratos vinculados
                </TitleH3>
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <label htmlFor="host">Host</label>
                <Input
                  name="host"
                  type="text"
                  maxLength="20"
                  autoComplete="off"
                  required
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <label htmlFor="username_db">Username DB</label>
                <Input
                  name="username_db"
                  type="text"
                  maxLength="255"
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex={1}>
                <label htmlFor="password_db">Password DB</label>
                <Input
                  style={{ flex: 1 }}
                  name="password_db"
                  type={showPass ? 'text' : 'password'}
                  maxLength="100"
                  autoComplete="off"
                  value={passwordDb}
                  onChange={(e) => setPasswordDb(e.target.value)}
                />
              </Field>

              <Field flex="none">
                <label htmlFor="none">&nbsp;</label>
                <Button
                  style={{
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginLeft: 0,
                    maxWidth: 200,
                  }}
                  type="button"
                  onClick={() => setShowPass(!showPass)}>
                  {showPass ? (
                    <>
                      <MdVisibility size={18} color={colors.iconLight} />
                    </>
                  ) : (
                    <>
                      <MdVisibilityOff size={18} color={colors.iconLight} />
                    </>
                  )}
                </Button>
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        <Field>
          <Tabs>
            <TabList>
              <Tab>Contratos</Tab>
            </TabList>

            {server && (
              <TabPanel>
                <ServerContracts server={server} maxWidthPercent={100} />
              </TabPanel>
            )}
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

ServerForm.propTypes = {
  location: PropTypes.shape(),
};

ServerForm.defaultProps = {
  location: null,
};

export default ServerForm;

import React, { useState, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdWarning,
  MdBlock,
  MdLock,
  MdLockOpen,
  MdFileUpload,
  MdSchedule,
  MdRefresh,
  MdStorage,
} from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import ClipboardButton from '~/components/ClipboardButton';
import { Button } from '~/components/Buttons/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import Card from '~/components/Card';
import Modal from '~/components/Modal';
import useModal from '~/components/Modal/useModal';
import ButtonGroup from '~/components/Buttons/ButtonGroup';
import { LinkButton } from '~/components/Buttons/LinkButton';
import SearchInput from '~/components/Inputs/SearchInput';

import formatContract from '~/lib/formatContract';
import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

import ContractStatus from './ContractStatus';
import {
  Header,
  CellWrapper,
  ModalContainer,
  UploadingContainer,
} from './styles';

function Contracts() {
  const filesElement = useRef(null);
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('active');
  const [contracts, setContracts] = useState([]);
  const time = useRef(null);
  const uploadTimer = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });

  const { isShowing, toggle } = useModal();

  const [notImportedTxt, setNotImportedTxt] = useState('');
  const [refresh, setRefresh] = useState(true);

  const [currentModal, setCurrentModal] = useState('');

  useEffect(() => {
    async function loadContracts(f) {
      try {
        setLoading(true);
        const response = await api.get('contracts', {
          params: {
            q: f,
            status,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((c) => formatContract(c));

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setContracts(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'contracts:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadContracts(filter);
    }, 600);
  }, [filter, status, page, pageLimit, order, role, refresh]);

  const handleFilterChange = (value) => {
    setPage(1);
    setFilter(value);
  };

  const reload = () => {
    // setFilter(' ');
    // setFilter('');
    setRefresh(!refresh);
  };

  const handleCreate = () => {
    history.push('/contracts/create');
  };

  const handleEdit = (contract_id) => {
    if (!contract_id) return;
    history.push({ pathname: '/contracts/edit', contract_id });
  };

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleDelete = async (contract_id) => {
    if (!window.confirm('Deseja mesmo cancelar o contrato?')) {
      return;
    }

    try {
      await api.delete(`contracts/${contract_id}`);
      toast.success('Contrato cancelado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao cancelar contrato! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  };

  const handleBlockUpdates = async ({ block_update, contract_id }) => {
    if (!contract_id) {
      const msg = `Deseja mesmo ${
        block_update ? 'bloquear' : 'desbloquear'
      } atualizações de sistema de todos os contratos?'`;

      if (!window.confirm(msg)) {
        return;
      }
    }

    try {
      const route = contract_id
        ? `contracts/${contract_id}/block-updates`
        : 'contracts/all/block-updates';

      await api.put(route, { block_update });

      if (!contract_id) {
        const msgSuccess = contract_id
          ? `Atualização ${
              block_update ? 'bloqueada' : 'desbloqueada'
            } com sucesso!`
          : `Atualizações ${
              block_update ? 'bloqueadas' : 'desbloqueadas'
            } com sucesso!`;

        toast.success(msgSuccess);
      }

      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao bloquear {contract_id ? 'atualização!' : 'atualizações!'}
          <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  };

  const handleFileChange = async (e) => {
    // Evita que o loading "pisque" na tela se houver algum erro de upload
    clearTimeout(uploadTimer.current);
    uploadTimer.current = setTimeout(() => setUploading(true), 600);

    const data = new FormData();
    data.append('file', e.target.files[0]);
    try {
      const response = await api.post('contracts/import', data);
      // toggle();
      // reload();

      const { errors, imported, notImported } = response.data;

      if (errors && errors.length > 0) {
        throw new Error(errors.join());
      }

      let txt = '';
      notImported.map((item) => {
        // txt = `${txt} <br /><br /> ${item.customer_name}`;
        txt += `${item.customer_name} (${item.motive})\r\n`;
        return item;
      });

      setUploading(false);
      setNotImportedTxt(txt);
      clearTimeout(uploadTimer.current);

      if (
        imported &&
        imported.length > 0 &&
        notImported &&
        notImported.length > 0
      ) {
        toast.warn(<div>Algumas linhas não foram importadas.</div>);
      }

      if (
        imported &&
        imported.length === 0 &&
        notImported &&
        notImported.length > 0
      ) {
        toast.warn(<div>Nenhuma linha foi importada.</div>);
      }

      if (imported && imported.length > 0) {
        toast.success(
          <div>
            Arquivo importado com sucesso!
            <br /> <br />
            Foram importados {imported.length} linhas.
          </div>
        );
      }

      if (
        imported &&
        imported.length === 0 &&
        notImported &&
        notImported.length === 0
      ) {
        toast.info('Nada foi feito.');
      }
    } catch (err) {
      clearTimeout(uploadTimer.current);
      setUploading(false);
      toast.error(
        <div>
          Falha ao importar arquivo CSV
          <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  };

  const handleContractAccessBlockFileChange = async (e) => {
    clearTimeout(uploadTimer.current);
    uploadTimer.current = setTimeout(() => setUploading(true), 600);

    const data = new FormData();
    data.append('file', e.target.files[0]);
    try {
      const response = await api.post('contracts/import/access-block', data);
      toggle();
      reload();
      const { errors, imported, notImported } = response.data;

      if (errors && errors.length > 0) {
        throw new Error(errors.join());
      }

      let txt = '';
      notImported.map((item) => {
        // txt = `${txt} <br /><br /> ${item.customer_name}`;
        txt += `${item.customer_name} (${item.motive})\r\n`;
        return item;
      });

      setUploading(false);
      setNotImportedTxt(txt);
      clearTimeout(uploadTimer.current);

      if (
        imported &&
        imported.length > 0 &&
        notImported &&
        notImported.length > 0
      ) {
        toast.warn(<div>Algumas linhas não foram importadas.</div>);
      }

      if (
        imported &&
        imported.length === 0 &&
        notImported &&
        notImported.length > 0
      ) {
        toast.warn(<div>Nenhuma linha foi importada.</div>);
      }

      if (imported && imported.length > 0) {
        toast.success(
          <div>
            Arquivo importado com sucesso!
            <br /> <br />
            Foram importados {imported.length} linhas.
          </div>
        );
      }

      if (
        imported &&
        imported.length === 0 &&
        notImported &&
        notImported.length === 0
      ) {
        toast.info('Nada foi feito.');
      }
    } catch (err) {
      clearTimeout(uploadTimer.current);
      setUploading(false);
      toast.error(
        <div>
          Falha ao importar arquivo CSV
          <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  };

  const handleCloseImportModal = () => {
    setUploading(false);
    setNotImportedTxt('');
    toggle();
    reload();
  };

  const handleClearFile = () => {
    setUploading(false);
    setNotImportedTxt('');
    filesElement.current.value = null;
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Contratos {total > 0 && `(${total})`}</Title>

      <Modal isShowing={isShowing} hide={handleCloseImportModal}>
        <ModalContainer>
          {currentModal === 'contracts' ? (
            <div>
              <Title>Importar arquivo .CSV</Title>
              <br />
              <br />
              <strong>Headers do arquivo CSV:</strong>
              <span>
                customer_name,customer_cpf_cnpj,contract_token,description,start_date,max_licenses,access_block_date,block_update,path_db,remote_db,username_db,password_db
              </span>
              <br />
              <br />
              <input
                type="file"
                // multiple
                accept=".csv"
                // data-file={file}
                onChange={handleFileChange}
                onClick={handleClearFile}
                ref={filesElement}
              />
              <br />
              <br />
              {uploading && (
                <UploadingContainer>
                  <Loader
                    type="Oval"
                    color={colors.color_3}
                    height={80}
                    width={80}
                  />
                </UploadingContainer>
              )}
              {notImportedTxt !== '' && (
                <textarea
                  style={{ color: 'red' }}
                  name="Text1"
                  cols="80"
                  rows="30"
                  value={notImportedTxt}
                  readOnly
                />
              )}
            </div>
          ) : (
            <div>
              <Title>Importar Bloqueios Financeiros .CSV</Title>
              <br />
              <br />
              <strong>Headers do arquivo CSV:</strong>
              <span>
                customer_cpf_cnpj,contract_token,access_block_date,finance_note
              </span>
              <br />
              <br />
              <input
                type="file"
                // multiple
                accept=".csv"
                // data-file={file}
                onChange={handleContractAccessBlockFileChange}
                onClick={handleClearFile}
                ref={filesElement}
              />
              <br />
              <br />
              {uploading && (
                <UploadingContainer>
                  <Loader
                    type="Oval"
                    color={colors.color_3}
                    height={80}
                    width={80}
                  />
                </UploadingContainer>
              )}
              {notImportedTxt !== '' && (
                <textarea
                  style={{ color: 'red' }}
                  name="Text1"
                  cols="80"
                  rows="30"
                  value={notImportedTxt}
                  readOnly
                />
              )}
            </div>
          )}
        </ModalContainer>
      </Modal>

      <Header>
        <CellWrapper>
          <SearchInput
            placeholder="descrição, cliente, cnpj, token, alias, path DB, remote DB, servidor"
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
          />
          <ButtonGroup
            buttons={[
              { label: 'Ativos', value: 'active' },
              { label: 'Teste', value: 'test' },
              { label: 'Suspensos', value: 'susp' },
              { label: 'Cancelados', value: 'cancel' },
            ]}
            initValue={status}
            onClick={(e) =>
              setStatus(status !== e.target.value ? e.target.value : '')
            }
          />
        </CellWrapper>
        <CellWrapper>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="contracts:edit"
            yes={() => (
              <Card dropAlign="right">
                <Can
                  role={role}
                  perform="contracts/tech:edit"
                  yes={() => (
                    <li>
                      <MdLock size={20} color={colors.textDark} />
                      <button
                        type="button"
                        onClick={() =>
                          handleBlockUpdates({ block_update: true })
                        }>
                        Bloquear atualização de todos
                      </button>
                    </li>
                  )}
                />

                <Can
                  role={role}
                  perform="contracts/tech:edit"
                  yes={() => (
                    <li>
                      <MdLockOpen size={20} color={colors.color_2} />
                      <button
                        type="button"
                        onClick={() =>
                          handleBlockUpdates({ block_update: false })
                        }>
                        Desbloquear atualização de todos
                      </button>
                    </li>
                  )}
                />

                {/* Importação de novos contratos em massa (escondida para evitar importação acidental)  */}
                {/* <Can
                  role={role}
                  perform="contracts/tech:edit"
                  yes={() => (
                    <li>
                      <MdFileUpload size={20} color={colors.color_2} />
                      <button
                        type="button"
                        onClick={() => {
                          toggle();
                          setCurrentModal('contracts');
                        }}>
                        Importar arquivo .CSV
                      </button>
                    </li>
                  )}
                /> */}

                <Can
                  role={role}
                  perform="contracts/finance:edit"
                  yes={() => (
                    <li>
                      <MdFileUpload size={20} color={colors.color_2} />
                      <button
                        type="button"
                        onClick={() => {
                          toggle();
                          setCurrentModal('blockedContracts');
                        }}>
                        Importar Bloqueios Financeiros .CSV
                      </button>
                    </li>
                  )}
                />
              </Card>
            )}
          />

          <Can
            role={role}
            perform="contracts:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </CellWrapper>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Descrição"
                showOrder={order.field === 'description'}
                asc={order.asc}
                onClick={() => handleChangeOrder('description')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Código do Cliente"
                showOrder={order.field === 'alias'}
                asc={order.asc}
                onClick={() => handleChangeOrder('alias')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Limite de Licenças"
                showOrder={order.field === 'licenses_count'}
                asc={order.asc}
                onClick={() => handleChangeOrder('licenses_count')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Limite de Usuários"
                showOrder={order.field === 'max_users'}
                asc={order.asc}
                onClick={() => handleChangeOrder('max_users')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Usuários Ativos"
                showOrder={order.field === 'users_count'}
                asc={order.asc}
                onClick={() => handleChangeOrder('users_count')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'status'}
                asc={order.asc}
                onClick={() => handleChangeOrder('status')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Bloqueio de Acesso"
                showOrder={order.field === 'access_block_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('access_block_date')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Servidor"
                showOrder={order.field === 'server_info'}
                asc={order.asc}
                onClick={() => handleChangeOrder('server_info')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>

            <th> </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {contracts.map((c) => (
            <tr key={c.id}>
              <td>{c.description}</td>
              <td>
                <CellWrapper>
                  <Can
                    role={role}
                    perform="contracts:edit"
                    yes={() => (
                      <LinkButton
                        style={{ minWidth: '50px' }}
                        onClick={() => handleEdit(c.id)}>
                        {c.alias}
                      </LinkButton>
                    )}
                    no={() => <>{c.alias}</>}
                  />
                  <div>
                    <ClipboardButton value={c.alias} />
                  </div>
                </CellWrapper>
              </td>
              <td>
                {c.licenseAlerts.length > 0 ? (
                  <CellWrapper>
                    {c.max_licenses}
                    <MdWarning
                      color={colors.statusYellow}
                      size={24}
                      data-tip
                      data-for={`licenses_count_${c.id}`}
                    />
                    <ReactTooltip
                      id={`licenses_count_${c.id}`}
                      textColor={colors.textLight}
                      backgroundColor={colors.statusYellow}>
                      <div style={{ padding: 8 }}>
                        {c.licenseAlerts.join(', ')}
                      </div>
                    </ReactTooltip>
                  </CellWrapper>
                ) : (
                  c.max_licenses
                )}
              </td>
              <td>{c.max_users}</td>
              <td>
                <CellWrapper>
                  <div
                    data-tip
                    data-for={`users_count_${c.id}`}
                    style={{ padding: 8 }}>
                    {c.users_count}
                  </div>
                  {c.userLimitExceeded ? (
                    <CellWrapper>
                      <MdWarning
                        color={colors.statusRedStrong}
                        size={24}
                        data-tip
                        data-for={`users_count_limit_exceeded_${c.id}`}
                      />
                      <ReactTooltip
                        id={`users_count_limit_exceeded_${c.id}`}
                        textColor={colors.textLight}
                        backgroundColor={colors.statusRedStrong}>
                        <div style={{ padding: 8 }}>
                          Limite de usuários excedido.
                        </div>
                      </ReactTooltip>
                    </CellWrapper>
                  ) : null}
                  {c.users_count ? (
                    <ReactTooltip
                      id={`users_count_${c.id}`}
                      textColor={colors.textLight}
                      backgroundColor={colors.color_4}>
                      <div style={{ padding: 8 }}>
                        Atualizado automaticamente {c.usersCountUpdatedAt}
                      </div>
                    </ReactTooltip>
                  ) : null}
                </CellWrapper>
              </td>
              <td>
                <ContractStatus status={c.status} />
              </td>
              <td>
                <CellWrapper>
                  {c.accessBlockDateFormatted &&
                    (c.bockScheduled ? (
                      <>
                        <MdBlock
                          color="#DE3B3B"
                          size={16}
                          data-tip
                          data-for={`access_block_date_${c.id}`}
                        />
                        <ReactTooltip
                          id={`access_block_date_${c.id}`}
                          textColor={colors.color_5}
                          backgroundColor={colors.color_1}>
                          Sistema bloqueado!
                        </ReactTooltip>
                      </>
                    ) : (
                      <>
                        <MdSchedule
                          color={colors.color_4}
                          size={16}
                          data-tip
                          data-for={`access_block_date_${c.id}`}
                        />
                        <ReactTooltip
                          id={`access_block_date_${c.id}`}
                          textColor={colors.color_5}
                          backgroundColor={colors.color_1}>
                          Bloqueio agendado para {c.accessBlockDateFormatted}
                        </ReactTooltip>
                      </>
                    ))}
                  {c.accessBlockDateFormatted}
                </CellWrapper>
              </td>
              <td>{c.server_info}</td>
              <td>{c.createdAtFormatted}</td>
              <td>
                {c.path_db || c.remote_db ? (
                  <CellWrapper>
                    <MdStorage
                      size={24}
                      color={colors.color_1}
                      data-tip
                      data-for={`has_database_icon_${c.id}`}
                    />
                    <ReactTooltip
                      id={`has_database_icon_${c.id}`}
                      textColor={colors.textDark}
                      backgroundColor={colors.color_1}>
                      <div style={{ padding: 8 }}>
                        Possui banco de dados configurado.
                      </div>
                    </ReactTooltip>
                  </CellWrapper>
                ) : null}
              </td>
              <td>
                <Actions height={90} width={150}>
                  {/* <li>
                    <MdRemoveRedEye color={colors.primary} size={16} />
                    <button type="button" onClick={() => handleShow(c.id)}>
                      Visualizar
                    </button>
                  </li> */}

                  <Can
                    role={role}
                    perform="contracts:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button type="button" onClick={() => handleEdit(c.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button type="button" onClick={() => handleEdit(c.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    role={role}
                    perform="contracts:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(c.id)}>
                          Cancelar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Cancelar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && contracts.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default Contracts;

import styled from 'styled-components';
import colors from '~/styles/colors';

export const Title = styled.h1`
  font-size: 18px;
  color: ${colors.textDarker};
`;

export const TitleH2 = styled.h2`
  font-size: 16px;
  color: ${colors.textDarker};
  padding: 16px 0;
`;

export const TitleH3 = styled.h3`
  font-size: 14px;
  color: ${colors.textDarker};
  padding: 16px 0;
`;

import { firebirdConnectionRegex } from '~/config/regex';

function extractFirebirdConnectionParts(connectionPath) {
  if (!connectionPath) return { host: null, port: null, path: null };

  const match = connectionPath.match(firebirdConnectionRegex);

  if (!match || !match.groups) {
    return { host: null, port: null, path: null };
  }

  const { host, port, path } = match.groups;

  return { host, port, path };
}

export { extractFirebirdConnectionParts }; // eslint-disable-line

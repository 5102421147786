import React, { useState, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { MdWarning, MdBlock, MdSchedule, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import { format, parseISO, isBefore } from 'date-fns';
import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import ClipboardButton from '~/components/ClipboardButton';
import { Button } from '~/components/Buttons/Button';
import { LinkButton } from '~/components/Buttons/LinkButton';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Pagination from '~/components/Pagination';
import ButtonGroup from '~/components/Buttons/ButtonGroup';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import SearchInput from '~/components/Inputs/SearchInput';

import ContractStatus from './ContractStatus';
import { Header, CellWrapper } from './styles';

function ContractReport() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('active');
  const [contracts, setContracts] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadContracts(f) {
      try {
        setLoading(true);
        const response = await api.get('reports/contracts/contract-report', {
          params: {
            q: f,
            status,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const { docs } = response.data;

        if (!docs) {
          throw new Error('Dados inválidos');
        }

        const data = docs.map((c) => {
          const licenseAlerts = [];

          if (c.licenses_count < c.max_licenses) {
            licenseAlerts.push('O contrato ainda tem licenças não geradas');
          }
          if (
            c.licenses_count &&
            c.licenses_count > 0 &&
            c.licenses_activated < c.licenses_count
          ) {
            licenseAlerts.push(
              'Uma ou mais licenças não foram ativadas ainda.'
            );
          }

          return {
            ...c,
            startDateFormatted:
              c.start_date && format(parseISO(c.start_date), 'dd/MM/yyyy'),
            startDateEn:
              c.start_date && format(parseISO(c.start_date), 'yyyy-MM-dd'),
            accessBlockDateEn:
              c.access_block_date &&
              format(parseISO(c.access_block_date), 'yyyy-MM-dd'),
            accessBlockDateFormatted:
              c.access_block_date &&
              format(parseISO(c.access_block_date), 'dd/MM/yyyy'),
            bockScheduled: c.access_block_date
              ? !isBefore(new Date(), parseISO(c.access_block_date))
              : false,
            createdAtFormatted:
              c.createdAt && format(parseISO(c.createdAt), 'dd/MM/yyyy HH:mm'),
            lastAccessFormatted:
              c.last_access &&
              format(parseISO(c.last_access), 'dd/MM/yyyy HH:mm'),
            licenseAlerts,
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setContracts(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'contracts:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadContracts(filter);
    }, 600);
  }, [filter, status, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleEdit(contract_id) {
    if (!contract_id) return;
    history.push({ pathname: '/contracts/edit', contract_id });
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Relatório de Contratos {total > 0 && `(${total})`}</Title>

      <Header>
        <CellWrapper>
          <SearchInput
            placeholder="cliente (nome, cpf, cnpj), descrição, token, alias, servidor"
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
          />
          <ButtonGroup
            buttons={[
              { label: 'Ativos', value: 'active' },
              { label: 'Teste', value: 'test' },
              { label: 'Suspensos', value: 'susp' },
              { label: 'Cancelados', value: 'cancel' },
            ]}
            initValue={status}
            onClick={(e) =>
              setStatus(status !== e.target.value ? e.target.value : '')
            }
          />
        </CellWrapper>

        <CellWrapper>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>
        </CellWrapper>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Descrição"
                showOrder={order.field === 'description'}
                asc={order.asc}
                onClick={() => handleChangeOrder('description')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Contract Token"
                showOrder={order.field === 'contract_token'}
                asc={order.asc}
                onClick={() => handleChangeOrder('contract_token')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Licenças"
                showOrder={order.field === 'licenses_count'}
                asc={order.asc}
                onClick={() => handleChangeOrder('licenses_count')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Último Acesso"
                showOrder={order.field === 'last_access'}
                asc={order.asc}
                onClick={() => handleChangeOrder('last_access')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'status'}
                asc={order.asc}
                onClick={() => handleChangeOrder('status')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Bloqueio de Acesso"
                showOrder={order.field === 'access_block_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('access_block_date')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Servidor"
                showOrder={order.field === 'server_info'}
                asc={order.asc}
                onClick={() => handleChangeOrder('server_info')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {contracts.map((c) => (
            <tr key={c.id}>
              <td>{c.description}</td>
              <td>
                <CellWrapper>
                  <Can
                    role={role}
                    perform="contracts:edit"
                    yes={() => (
                      <LinkButton
                        style={{ minWidth: '236px' }}
                        onClick={() => handleEdit(c.id)}>
                        {c.contract_token}
                      </LinkButton>
                    )}
                    no={() => <>{c.contract_token}</>}
                  />
                  <div>
                    <ClipboardButton value={c.contract_token} />
                  </div>
                </CellWrapper>
              </td>
              <td>
                {c.licenseAlerts.length > 0 ? (
                  <CellWrapper>
                    {c.max_licenses}
                    <MdWarning
                      color={colors.statusYellow}
                      size={24}
                      data-tip
                      data-for={`licenses_count_${c.id}`}
                    />
                    <ReactTooltip
                      id={`licenses_count_${c.id}`}
                      textColor={colors.textLight}
                      backgroundColor={colors.statusYellow}>
                      {c.licenseAlerts.join(', ')}
                    </ReactTooltip>
                  </CellWrapper>
                ) : (
                  c.max_licenses
                )}
              </td>
              <td>{c.lastAccessFormatted || 'Nunca acessou'}</td>
              <td>
                <ContractStatus status={c.status} />
              </td>
              <td>
                <CellWrapper>
                  {c.accessBlockDateFormatted &&
                    (c.bockScheduled ? (
                      <>
                        <MdBlock
                          color="#DE3B3B"
                          size={16}
                          data-tip
                          data-for={`access_block_date_${c.id}`}
                        />
                        <ReactTooltip
                          id={`access_block_date_${c.id}`}
                          textColor={colors.color_5}
                          backgroundColor={colors.color_1}>
                          Sistema bloqueado!
                        </ReactTooltip>
                      </>
                    ) : (
                      <>
                        <MdSchedule
                          color={colors.color_4}
                          size={16}
                          data-tip
                          data-for={`access_block_date_${c.id}`}
                        />
                        <ReactTooltip
                          id={`access_block_date_${c.id}`}
                          textColor={colors.color_5}
                          backgroundColor={colors.color_1}>
                          Bloqueio agendado para {c.accessBlockDateFormatted}
                        </ReactTooltip>
                      </>
                    ))}
                  {c.accessBlockDateFormatted}
                </CellWrapper>
              </td>
              <td>{c.server_info}</td>
              <td>{c.createdAtFormatted}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && contracts.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default ContractReport;

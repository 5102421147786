import {
  format,
  formatDistanceToNowStrict,
  isBefore,
  parseISO,
} from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

function formatContract(c) {
  const licenseAlerts = [];

  if (c.licenses_count < c.max_licenses) {
    licenseAlerts.push('O contrato ainda tem licenças não geradas');
  }
  if (
    c.licenses_count &&
    c.licenses_count > 0 &&
    c.licenses_activated < c.licenses_count
  ) {
    licenseAlerts.push('Uma ou mais licenças não foram ativadas ainda.');
  }

  return {
    ...c,
    startDateFormatted:
      c.start_date && format(parseISO(c.start_date), 'dd/MM/yyyy'),
    startDateEn: c.start_date && format(parseISO(c.start_date), 'yyyy-MM-dd'),
    accessBlockDateEn:
      c.access_block_date &&
      format(parseISO(c.access_block_date), 'yyyy-MM-dd'),
    accessBlockDateFormatted:
      c.access_block_date &&
      format(parseISO(c.access_block_date), 'dd/MM/yyyy'),
    bockScheduled: c.access_block_date
      ? !isBefore(new Date(), parseISO(c.access_block_date))
      : false,
    createdAtFormatted:
      c.createdAt && format(parseISO(c.createdAt), 'dd/MM/yyyy'),
    licenseAlerts,
    usersCountUpdatedAt:
      c.users_count_updated_at &&
      formatDistanceToNowStrict(parseISO(c.users_count_updated_at), {
        locale: pt,
        addSuffix: true,
      }),
    userLimitExceeded:
      c.max_users && c.users_count && c.users_count > c.max_users,
  };
}

export default formatContract;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Check, Form, Input } from '@rocketseat/unform';
import { MdArrowBack, MdDone } from 'react-icons/md';
import PropTypes from 'prop-types';

import colors from '~/styles/colors';

import api from '~/services/api';

import { extractFirebirdConnectionParts } from '~/lib/extractFirebirdConnectionParts';

import { Field, FieldGroup, FormWrapper } from '~/components/Forms';
import { InvisibleButton } from '~/components/Buttons/InvisibleButton';
import { HorizontalLeftContainer } from '~/components/Containers';
import ExecuteJobButton from '~/components/ExecuteJobButton';
import { AsyncCombo } from '~/components/AsyncCombo';
import { FormHeader } from '~/components/FormHeader';
import { Title } from '~/components/Title';
import Modal from '~/components/Modal';

function NewDBModal({
  contract_id,
  customer,
  isVisible,
  onClose,
  onSuccess,
  isJobRunning,
  setIsJobRunning,
  isForContractDatabases,
}) {
  const getDbFilename = useCallback((value) => {
    if (!value) return '';
    return value
      .normalize('NFD') // Remove acentos
      .replace(/[̀-ͯ]/g, '') // Remove diacríticos
      .toUpperCase() // Converte para maiúsculas
      .replace(/\s+/g, '_') // Substitui espaços por underscore
      .replace(/[^A-Z0-9_]/g, '')
      .concat('.FDB'); // Remove caracteres especiais
  }, []);

  const toPascalCase = useCallback((value) => {
    if (!value) return '';

    if (/^[A-Z][a-zA-Z]*(?:_[A-Z][a-zA-Z]*)*$/.test(value)) {
      return value; // Mantém se já estiver em PascalCase
    }
    return (
      value
        .normalize('NFD') // Remove acentos
        .replace(/[̀-ͯ]/g, '') // Remove diacríticos
        .toLowerCase() // Converte para minúsculas
        .replace(/[^a-z0-9\s]/g, '') // Remove caracteres especiais
        // .replace(/[^a-z0-9_\s]/g, "") // Permite underline, remove outros caracteres especiais
        .split(' ') // Divide em palavras
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Converte para PascalCase
        .join('')
    ); // Junta tudo sem espaços
  }, []);

  /**
   * States
   */
  const [dbFolder, setDbFolder] = useState(
    customer ? toPascalCase(customer) : ''
  );
  const [dbFilename, setDbFilename] = useState(
    customer ? getDbFilename(customer) : ''
  );
  const [sourceS3Uri, setSourceS3Uri] = useState();

  const [s3UriEnabled, setS3UriEnabled] = useState(false);
  const [loading] = useState(false);
  const [server, setServer] = useState();
  const [serverId, setServerId] = useState();

  // const [result, setResult] = useState();
  // const [error, setError] = useState();

  /**
   * Effects
   */

  useEffect(() => {
    async function loadServer() {
      if (!serverId) return;
      const response = await api.get(`servers/${serverId}`);
      setServer(response.data);
    }
    loadServer();
  }, [serverId]);

  useEffect(() => {
    if (!server) return;
    const file = customer ? getDbFilename(customer) : '';
    setDbFilename(`${server.prefix_db || ''}${file}`);
  }, [server, customer, getDbFilename]);

  const parcialPathDB = useMemo(() => {
    return `${server ? server.host : ''}:/${dbFolder || ''}/${
      dbFilename || ''
    }`;
  }, [dbFolder, dbFilename, server]);

  const isValid = useMemo(() => {
    if (!parcialPathDB) return false;
    const { host, path } = extractFirebirdConnectionParts(parcialPathDB);
    return !!host && !!path;
  }, [parcialPathDB]);

  /**
   * Handlers
   */

  // const handleCreateNewDB = () => {};

  const handleCancel = () => {
    if (!loading && !isJobRunning && !!onClose) {
      onClose();
    }
  };

  const handleServerChange = (newValue) => {
    if (!newValue) {
      setServer(undefined);
    }

    setServerId(newValue);
  };

  const handleDbFolderChange = (newValue) => {
    const sinitized = toPascalCase(newValue);

    if (sinitized !== dbFolder) {
      setDbFolder(sinitized);
    }
  };

  const handleSuccess = (value) => {
    if (onSuccess) {
      onSuccess(value);
    }
  };

  const handleS3UriChange = () => {
    setS3UriEnabled((prev) => !prev);
  };

  return (
    <Modal
      isShowing={isVisible}
      hide={handleCancel}
      hideDisabled={loading || isJobRunning}
      header={
        <FormHeader>
          <Title>Novo Banco de dados</Title>
        </FormHeader>
      }>
      <FormWrapper style={{ minWidth: 200 }}>
        <Form>
          <FieldGroup>
            <Field flex={1}>
              <AsyncCombo
                name="server"
                label="Servidor"
                route="servers"
                placeholder="Selecione..."
                isClearable
                idField="id"
                // labelField="name"
                labelExtractor={(item) =>
                  `${item.name} (${item.contract_count}${
                    item.max_databases ? `/${item.max_databases}` : ''
                  })`
                }
                onChange={handleServerChange}
                params={{ pageLimit: 100, active: true, availableOnly: true }}
                disabled={isJobRunning}
                required
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={1}>
              <HorizontalLeftContainer>
                <label htmlFor="db_folder">Pasta</label>
              </HorizontalLeftContainer>

              <Input
                name="db_folder"
                type="text"
                maxLength="100"
                autoComplete="off"
                value={dbFolder || ''}
                onChange={(e) => handleDbFolderChange(e.target.value)}
                disabled={isJobRunning}
                required
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={1}>
              <label htmlFor="db_filename">Arquivo .FDB</label>
              <Input
                name="db_filename"
                type="text"
                maxLength="100"
                autoComplete="off"
                value={dbFilename || ''}
                onChange={(e) => setDbFilename(e.target.value)}
                disabled={isJobRunning}
                required
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={1}>
              <HorizontalLeftContainer>
                <Check
                  name="source_s3_uri_check"
                  checked={s3UriEnabled}
                  onChange={handleS3UriChange}
                />
                <label htmlFor="source_s3_uri_check" style={{ paddingLeft: 6 }}>
                  S3 URI de Origem
                </label>
              </HorizontalLeftContainer>
              <Input
                name="source_s3_uri"
                type="text"
                maxLength="255"
                autoComplete="off"
                value={sourceS3Uri || ''}
                onChange={(e) => setSourceS3Uri(e.target.value)}
                disabled={isJobRunning || !s3UriEnabled}
                placeholder={
                  s3UriEnabled
                    ? 's3://bucket/servidor/bkp_BS_CLIENTE.FDB.zip'
                    : ''
                }
              />
              <small style={{ paddingTop: 4, color: colors.textDark }}>
                Precisa ser um arquivo .zip contendo apenas o arquivo .fbk
              </small>
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={1}>
              <FormHeader>
                <div />
                <div>
                  <InvisibleButton
                    type="button"
                    onClick={handleCancel}
                    disabled={loading || isJobRunning}>
                    <MdArrowBack size={24} color={colors.iconLight} />
                    <span>Cancelar</span>
                  </InvisibleButton>
                  <ExecuteJobButton
                    label="Criar novo banco de dados"
                    route={
                      isForContractDatabases
                        ? `/contracts/${contract_id}/databases/create-firebird-db/`
                        : `/contracts/${contract_id}/create-firebird-db/`
                    }
                    payload={{
                      server_id: serverId,
                      db_folder: dbFolder,
                      db_filename: dbFilename,
                      source_s3_uri: sourceS3Uri || null,
                    }}
                    icon={<MdDone size={18} color={colors.iconLight} />}
                    disabled={!isValid}
                    setIsRunning={setIsJobRunning}
                    onSuccess={handleSuccess}
                  />
                </div>
              </FormHeader>
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </Modal>
  );
}

NewDBModal.propTypes = {
  contract_id: PropTypes.number.isRequired,
  customer: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  isJobRunning: PropTypes.bool,
  setIsJobRunning: PropTypes.func,
  isForContractDatabases: PropTypes.bool,
};

NewDBModal.defaultProps = {
  customer: undefined,
  onClose: undefined,
  onSuccess: undefined,
  isJobRunning: false,
  setIsJobRunning: undefined,
  isForContractDatabases: false,
};

export default NewDBModal;

// eslint-disable-next-line
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/gm;

const emailRegex =
  /^(|((([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+(;[\s]?(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*))$/;

const dateRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

const dateRegexIso8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const firebirdConnectionRegex =
  /^(?<host>(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-_]*[a-zA-Z0-9])?\.)*[a-zA-Z0-9](?:[a-zA-Z0-9-_]*[a-zA-Z0-9])?))(?:\/(?<port>3050|[1-9][0-9]{3,4})?)?:(?<path>(?:(?:\/?[A-Za-z]:)?(?:\/(?:[^/<>"|?*]+(?: (?!\/)[^/<>"|?*]+)*\/)*[^/<>"|?*\s]+(?:\.(?:fdb|FDB))?)|[^/<>"|?*\s]+(?:\.(?:fdb|FDB))?))$/;

export {
  passwordRegex,
  emailRegex,
  dateRegex,
  dateRegexIso8601,
  firebirdConnectionRegex,
};
